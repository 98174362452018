@mixin font(
  $font-family: 'Inter',
  $color: black,
  $size: null,
  $weight: null,
  $line-height: normal,
  $style: normal,
  $letter-spacing: null
) {
  font-family: $font-family, 'sans-serif';
  font-size: $size;
  line-height: $line-height;
  font-weight: $weight;
  font-style: $style;
  color: $color;
  letter-spacing: $letter-spacing;
}

@mixin fixed-size($width, $height: null) {
  min-width: $width;
  max-width: $width;

  @if ($height == null) {
    min-height: $width;
    max-height: $width;
  } @else {
    min-height: $height;
    max-height: $height;
  }
}

@mixin hidden-scroll($axis: y) {
  @if $axis == y {
    overflow-y: scroll;
  } @else {
    overflow-x: scroll;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
    background: transparent;
    width: 0;
    height: 0;
  }
}

@mixin white-gradient {
  background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}