@import "src/styles/abstracts";

.slider {
  position: relative;
  overflow: hidden;
  width: 100%;

  @include font('Inter', #FFF, 14px, 700, 24px);

  &:before, &:after {
    background: linear-gradient(to left, rgba(17, 17, 17, 0) 0%, rgba(21, 21, 21, 1) 100%);
    content: "";
    height: 110%;
    position: absolute;
    width: 25%;
    z-index: 2;
    top: -5%;
  }

  &:after {
    right: -5px;
    transform: rotateZ(180deg);
  }

  &:before {
    left: -5px;
  }

  .slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    padding: 0 8px;

    .image {
      border-radius: 12px;

      @include fixed-size(107px, 160px);
    }

    .title {
      text-transform: none;
    }
  }
}

