@import "src/styles/abstracts";

.container {
  padding-top: 40px;
  max-width: 800px;
  width: 100%;
  text-align: left;
  align-self: center;

  .header {
    text-align: center;
    text-transform: uppercase;

    @include font('Druk Cyr', #FFF, 40px, 700, 88%, italic);
  }

  .body {
    margin-top: 40px;

    @include font('Inter', #FFF, 20px, 400, 125%, normal);
  }
}

@media screen and (max-width: 1294px) {
  .container {
    padding: 40px 24px;
  }
}