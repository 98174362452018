@import "src/styles/abstracts";

.previewContainer {
  display: flex;
  flex: 1;
  padding: 0 48px;
  width: 1280px;
  align-items: flex-end;

  .preview {
    margin-right: 20px;
    min-width: 500px;
    text-transform: uppercase;
    flex: 1;
    margin-bottom: 60px;

    @include font('Druk Cyr', #FFF, 88px, 700, 88px, italic);

    .title {
      margin: unset;
    }

    .subtitle {
      color: #DEFB77;
      font-size: 48px;
      font-weight: 500;
      line-height: 42.24px;
      margin-top: 64px;
    }

    .downloadLinks {
      align-items: center;
      margin-top: 24px;

      .link {
        display: flex;
        border-radius: 12px;
        background: #2B2B2B;
        padding: 16px 24px;
        margin-right: 16px;
        width: 315px;
        gap: 16px;

        &:first-of-type {
          margin-bottom: 16px;
        }

        .qrcode {
          @include fixed-size(50px);
        }

        .separator {
          width: 0;
          border-right: 1px solid #4C4D4F;
        }

        .download {
          @include fixed-size(180px, 45px);
        }
      }
    }
  }

  .phoneImage {
    flex: 1;
    width: 100%;
    align-self: flex-end;
  }
}

.content {
  //TODO: remove after release
  //display: none;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 48px 0;
  background: #151515;
  text-transform: uppercase;

  @include font('Druk Cyr', #818284, 32px, 700, 88%, italic);

  .header {
    display: none;
    text-transform: uppercase;

    .title {
      @include font('Druk Cyr', #FFF, 64px, 700, 88%, italic);
    }

    .subtitle {
      margin-top: 64px;

      @include font('Druk Cyr', #DEFB77, 32px, 700, 88%, italic);
    }

    .links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;

      .link {
        display: flex;
        border-radius: 10px;
        background: #2B2B2B;
        padding: 13px 20px;
        flex: 1;

        &:first-of-type {
          margin-right: 16px;
        }

        .download {
          @include fixed-size(100%, auto);
        }
      }
    }
  }

  .contentGroups {
    width: 100%;
    //TODO: remove after release
    //display: none;

    .contentGroup {
      //&:first-of-type {
      //  margin-bottom: 64px;
      //}

      &:not(:first-of-type) {
        margin-top: 32px;
      }

      .title {
        text-align: center;
      }

      .content {
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-around;
        justify-content: center;
        gap: 40px;



        > * {
          object-fit: contain;
          height: auto;
          width: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .previewContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 0 24px;
    width: 100%;

    .preview {
      display: none;
    }

    .phoneImage {
      max-width: 440px;
      height: calc(100vh);
      align-self: center;
    }
  }

  .content {
    display: block;
    padding: 24px;

    .header {
      display: block;

      .subtitle {
        text-align: center;
      }
    }

    .contentGroups {
      //margin-top: 108px;
      padding: 48px 0;
      //margin-bottom: 40px

      .carousel {
        margin: 0 -48px;
      }
    }
  }
}

@media screen and (min-width: 1921px) {
  .previewContainer {
    width: 1440px;

    .preview {
      .title {
      }

      .subtitle {
      }
    }

    .phoneImage {
      width: unset;
      height: 100%;
      min-height: 0;
    }
  }

  .content {
    .contentGroups {
      max-width: 1440px;
    }
  }
}