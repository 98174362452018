@import "~styles/abstracts";

.appContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #151515;
  background-size: cover;
  align-items: center;
  min-height: 100%;
  width: 100%;
  justify-content: center;

  .background {
    position: absolute;
    object-fit: cover;
    object-position: 55% 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
  };

  &.solid {
    .background {
      display: none;
    }
  }

  .layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      padding: 16px 48px;
      max-width: 1294px;

      .logo {
        display: flex;
        align-items: center;

        @include font('Druk Cyr', #DEFB77, 40px, 700, 88%, italic);

        .image {
          margin-right: 12px;

          @include fixed-size(64px);
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      width: 100%;
    }
  }

  .footer {
    width: 100%;
    padding: 48px 80px;
    background: #000;
    display: flex;
    justify-content: center;

    .content {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      max-width: 1294px;
      width: 100%;

      @include font('Inter', #FFF, 20px, 500, 24px);

      .downloadLink {
        display: block;
        margin-right: 45px;

        &.marketingKit {
          display: none;
          justify-content: center;
          align-items: center;
          margin-right: unset;

          .button {
            width: 210px;
          }
        }
      }

      .column {
        display: flex;
        flex-direction: column;
        flex: 1;

        .title {
          color: #818284;
        }

        .link {
          display: flex;
          align-items: center;
          margin-top: 24px;

          .icon {
            margin-right: 4px;

            @include fixed-size(24px);
          }
        }

        &:last-of-type {
          align-items: flex-end;
          justify-content: space-between;
        }
      }
    }
  }

  .layout, .footer {
    z-index: 1;
  }
}

.downloadLink {
  text-decoration: none;

  .button {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-radius: 16px;
    background: #DEFB77;
    outline: none;
    border: none;
    cursor: pointer;

    @include font('Druk Cyr', #151515, 32px, 700, 88%, italic);
  }
}

@media screen and (max-width: 1280px) {
  .appContainer {
    .background {
      height: 100vh;
    }

    .layout {
      max-width: unset;

      .header {
        padding: 16px 24px;

        .logo {
          font-size: 30px;

          .image {
            @include fixed-size(48px);
          }
        }
      }
    }

    .footer {
      padding: 48px 24px;

      .content {
        gap: 48px;
        flex-direction: column;

        .downloadLink {
          display: none;
          margin-right: 45px;

          &.marketingKit {
            display: flex;
            margin-right: unset;
          }
        }

        .column {
          &:last-of-type {
            align-items: unset;
            justify-content: unset;
          }
        }
      }
    }

    .downloadLink {
      .button {
        font-size: 24px;
      }
    }
  }
}

@media screen and (min-width: 1921px) {
  .appContainer {
    .background {
      height: 100%;
    }

    .layout {
      max-width: unset;

      .content {
        width: 100%;
      };

      .header {
        width: 1440px;
      }
    }

    .footer {
      padding: 48px 108px;

      .content {
        width: 1440px;
        max-width: unset;
      }
    }
  }
}