html {
  font-family: 'Inter', sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

html, body, #root {
  height: 100%;
  width: 100%;
}

main {
  display: block;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

a {
  text-decoration: none;
  background-color: unset;
  color: unset;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
select {
  text-transform: none;
}

button {
  padding: 0;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

ul {
  list-style-type: disc;
}

ul, ol {
  margin: 0 0 0 30px;
  padding: 0;
}

h2 {
  margin-top: 40px;
  font-weight: 600;
  font-size: 24px;
}

h3 {
  font-weight: 600;
  font-size: 20px;
}
