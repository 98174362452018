@import "src/styles/abstracts";

html {
  font-family: 'Dark Syr', sans-serif;
  box-sizing: border-box;
  font-feature-settings: 'clig' off, 'liga' off;
  background: #151515;

  @include hidden-scroll()
}

html, body, #root {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
